import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Accordion from 'react-bootstrap/Accordion'
import { Button, Card, Container } from 'react-bootstrap'
import SocialMedia from "../components/social-medias"
import Img from "gatsby-image"
import Image from '../components/image';
function FAQPage({ }) {
    // const sources = [
    //     data.mediaqueri.Media.childImageSharp.fluid,
    //     {
    //         ...data.desktop.Media.childImageSharp.fluid,
    //         media: `(min-width: 768px)`,
    //     },
    // ]
    return (
        <Layout>
            <SEO title="Frequently asked questions" />

            <div className={"FAQ-Header"}>
                {/* <Img
                    style={{
                        width: `100%`,
                        position: "absolute",

                        minHeight: "100%"
                        // maxWidth: 1200,
                        // padding: `0 1.0875rem 1.45rem`,
                    }}
                    fluid={sources}
                //{data.strapiFaqBanner.MediaTitle}
                /> */}
                <Image
                src="FAQ.jpg"
                className="mx-auto shadow-xl"
                alt="Gamotion matress image"
                style={{
                    width: `100%`,
                    position: "absolute",

                    minHeight: "100%"
                    // maxWidth: 1200,
                    // padding: `0 1.0875rem 1.45rem`,
                }}
            /> 
                <div className={"text"}>
                    <h1>FAQ</h1>
                    <p>Find here the most frequently asked questions</p>
                </div>
            </div>
            <Container className={"FAQ-Container"}>
                {/* {data.allStrapiFaq.nodes.map((node, index) => (
                    <Accordion key={index} defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    {node.Question}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>{node.Response}</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    </Accordion>)
                )
                } */}
                <Accordion key={1} defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                What are the rules of the game ?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>You can download the instructions on how to use the GAMotion mat in the “pdf documentation” section.</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    </Accordion>
                <Accordion key={2} defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                Can anyone participate ? 
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>The   GAMotion   game   has   been   developed for older people who are declared physically and cognitively able  to  participate  by  a  caregiver.  Participants  must  have  adequate  mobility  for  the  exercises  and  be  sufficiently  oriented  in  time  and space. Walking aids are allowed. The  GAMotion  game  is  designed  to  improve  physical  activity  levels  of  older  people,  both  within  and  outside  of  residential  care.  </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    </Accordion>
                <Accordion key={3} defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                what are the dimensions of the mat ?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>The mat measures 3.2 m x 1.4 m and  is assembled  from  21  squares,  each  measuring  50  cm  x  50  cm  and 10 mm thick.</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    </Accordion>
                <Accordion key={4} defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                Is the mat easy to maintain ?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>We recommend to clean the GAMotion mat with water, or a  mild detergent, which is any product that does not contain phosphate,    aggressive acid, alkaline substance, or chlorine.</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    </Accordion>
                <Accordion key={5} defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                How should I store the GAMotion mat?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>The device should be kept in a clean and  ventilated  place,  away  from  humidity   and   large   temperature   variation (-40°C/+65°C).</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    </Accordion>
            </Container>

            <div className={"banner-more"}>
                <div className={"banner-more-content"}>
                    <h2>
                        Didn't find an answer to your question ?
                    </h2>
                    <p>
                        Contact us by mail or find us on our social media.
                    </p>

                    <SocialMedia height={"50px"} />
                </div></div>
        </Layout>
    )
}
// function FAQPage({ data }) {
//     const sources = [
//         data.mediaqueri.Media.childImageSharp.fluid,
//         {
//             ...data.desktop.Media.childImageSharp.fluid,
//             media: `(min-width: 768px)`,
//         },
//     ]
//     return (
//         <Layout>
//             <SEO title="Frequently asked questions" />

//             <div className={"FAQ-Header"}>
//                 <Img
//                     style={{
//                         width: `100%`,
//                         position: "absolute",

//                         minHeight: "100%"
//                         // maxWidth: 1200,
//                         // padding: `0 1.0875rem 1.45rem`,
//                     }}
//                     fluid={sources}
//                 //{data.strapiFaqBanner.MediaTitle}
//                 />
//                 <div className={"text"}>
//                     <h1>FAQ</h1>
//                     <p>Find here the most frequently asked questions</p>
//                 </div>
//             </div>
//             <Container className={"FAQ-Container"}>
//                 {data.allStrapiFaq.nodes.map((node, index) => (
//                     <Accordion key={index} defaultActiveKey="0">
//                         <Card>
//                             <Card.Header>
//                                 <Accordion.Toggle as={Button} variant="link" eventKey="0">
//                                     {node.Question}
//                                 </Accordion.Toggle>
//                             </Card.Header>
//                             <Accordion.Collapse eventKey="0">
//                                 <Card.Body>{node.Response}</Card.Body>
//                             </Accordion.Collapse>
//                         </Card>

//                     </Accordion>)
//                 )
//                 }
//             </Container>

//             <div className={"banner-more"}>
//                 <div className={"banner-more-content"}>
//                     <h2>
//                         Didn't find an answer to your question ?
//                     </h2>
//                     <p>
//                         Contact us by mail or find us on our social media.
//                     </p>

//                     <SocialMedia height={"50px"} />
//                 </div></div>
//         </Layout>
//     )
// }

// export const query = graphql`
// query {
    
//   desktop: strapiFaqBanner {
   
//       Media {
//           childImageSharp {
//             fluid(quality: 100) {
//               ...GatsbyImageSharpFluid
//                  }
//             }
//         } 
//     }  
//   mediaqueri: strapiFaqBanner {
   
//       Media {
//           childImageSharp {
//             fluid {
//               ...GatsbyImageSharpFluid
//                  }
//             }
//         } 
//     }
  
//   allStrapiFaq {
//     nodes {
//       Question
//       Response
//     }
//   }
// }
// `
export default FAQPage